.help-pic img {
  width: 100%;
}

.faq-video {
  margin: 50px;
  height: 400px;
  border-radius: 20px;
}

.faq-video1 {
  margin: 50px;
  height: 600px;
  border-radius: 20px;
}

p {
  font-size: 17px;
}

.tablet-img {
  border-radius: 20px;
  box-shadow: 0px 10px 20px 0px #eaeaea;
}

.tablet-container {
  display: flex;
  align-items: center;
}

.mobile-container {
  position: relative;
  width: 500px;
  height: 500px;
  /* transform: scale(0.8); */
  z-index: 1;
  margin-top: 50px;
}

.condition .card .card-body {
  min-height: 450px;
}

.tablet-text {
  font-size: 1.3rem;
  text-align: justify;
  padding: 20px;
  margin-left: 20px;
}

.cashone-title {
  font-weight: bold;
  color: #532e8f;
}
.cashone-h3 {
  font-weight: bold;
  color: #532e8f;
}

.btn-outline-neutral:hover,
.btn-outline-neutral:focus,
.btn-outline-neutral:active {
  color: #fbfefe !important;
}

.btn-outline-neutral:hover,
.btn-outline-neutral:focus,
.btn-outline-neutral:active,
.btn-outline-neutral.active,
.btn-outline-neutral:active:focus,
.btn-outline-neutral:active:hover,
.btn-outline-neutral.active:focus,
.show > .btn-outline-neutral.dropdown-toggle {
  background-color: #532e8f !important;
}

.mobile {
  height: 550px;
  z-index: 5;
  position: absolute;
  left: 35px;
  top: -30px;
  transition: all 0.75s ease-out;
}
.header-text {
  display: flex;
  align-items: center;
}

.header-text .motto {
  margin-top: 100px;
}
.page-header .filter::after {
  background-image: linear-gradient(180deg, #ffffff 0%, #ecf7f7 100%);
}

.btn-outline-neutral {
  border-color: #843ece;
  color: #423a3a;
}

.bag {
  height: 200px;
  z-index: 4;
  position: absolute;
  left: 0px;
  top: 40px;
  transition: all 0.75s ease-out;
}

.circle-mobile {
  height: 200px;
  z-index: 4;
  position: absolute;
  right: 30px;
  top: 20px;
  transition: all 0.75s ease-out;
}

.cup {
  height: 150px;
  z-index: 6;
  position: absolute;
  right: 150px;
  bottom: 0px;
  transition: all 0.75s ease-out;
}

.home_card {
  height: 120px;
  z-index: 6;
  position: absolute;
  left: 0px;
  bottom: 80px;
  transition: all 0.75s ease-out;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.phone-time p {
  padding: 20px;
  font-size: 21px;
}
.main .page-header .motto {
  color: #505050;
}

.main .page-header .motto h3 {
  text-align: justify !important;
}

.navbar-transparent .navbar-nav .nav-item .nav-link:not(.btn),
[class*="bg"] .navbar-nav .nav-item .nav-link:not(.btn) {
  color: black;
}

.navbar.navbar-color .navbar-toggler .navbar-toggler-bar {
  background: black !important;
}

.navbar.navbar-transparent {
  background-color: white !important;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%) !important;
}

.navbar.navbar-transparent .navbar-toggler .navbar-toggler-bar {
  background-color: rgb(0, 0, 0) !important;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%) !important;
}

.navbar.navbar-color {
  background-color: white;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%) !important;
}

.navbar.navbar-color .navbar-nav .nav-item .nav-link {
  color: black;
}

.main .page-header .filter::after {
  background-color: #f8f9fa;
}

.footer {
  border-top: 1px solid #f8f9fa;
}

.card-swiper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  /* width: 80%; */
  flex-direction: row;
}

@media screen and (max-width: 480px) {
  .card-swiper-container {
    flex-direction: column;
    margin-top: 30px;
    height: auto;
  }
  .card-swiper-2,
  .card-swiper-3,
  .card-swiper-4,
  .card-swiper-5,
  .card-swiper {
    height: 250px !important;
  }
}

@media screen and (max-width: 480px) {
  .header-text {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .faq-video {
    width: 100%;
    height: 100%;
    margin: 10px 0px 10px 0px;
  }
  .faq-video1 {
    width: 80%;
    height: 80%;
    margin: 10px 0px 10px 0px;
  }
}

img {
  border-radius: 20px;
}

h2 {
  margin-bottom: 40px;
  margin-top: 40px;
}

@media screen and (max-width: 480px) {
  .mobile-container {
    transform: scale(0.8);
    transform: translate(20px, 20px) scale(0.8);
  }
}

@media screen and (min-width: 480px) {
  .mobile-motto {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .tablet-text {
    margin-left: 0px;
  }
}

@media screen and (max-width: 480px) {
  .tablet-container {
    flex-direction: column;
  }
  .tablet-container img {
    width: 100%;
  }
}

.section-dark {
  background: linear-gradient(132deg, #ba86ef 0%, #431b84 100%);
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  margin: 0px 20px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide div {
  transition: ease-in 1s;
}

.swiper-slide div:hover {
  background-color: black;
}

.swiper-wrapper li:last-of-type {
  margin-right: 200px;
}

.card-swiper {
  animation: bounce 3s infinite ease-in-out;
  /* background: linear-gradient(132deg, #ba86ef 0%, #431b84 100%); */
  background-color: white;
  height: 80%;
  width: 300px;
  align-items: center;
  /* justify-content: center; */
  display: flex;
  font-size: 35px;
  color: #532e8f;
  font-weight: bold;
  border-radius: 20px;
  margin: 20px;
  flex-direction: column;
  padding: 50px 10px 50px 10px;
}

.card-swiper-2 {
  animation: bounce-2 3s infinite ease-in-out;
  background-color: white;
  height: 80%;
  width: 300px;
  align-items: center;
  /* justify-content: center; */
  display: flex;
  font-size: 35px;
  color: #532e8f;
  font-weight: bold;
  border-radius: 20px;
  margin: 20px;
  flex-direction: column;
  padding: 50px 10px 50px 10px;
}

.card-swiper-3 {
  animation: bounce-3 3s infinite ease-in-out;
  background-color: white;
  height: 80%;
  width: 300px;
  align-items: center;
  /* justify-content: center; */
  display: flex;
  font-size: 35px;
  color: #532e8f;
  font-weight: bold;
  border-radius: 20px;
  margin: 20px;
  flex-direction: column;
  padding: 50px 10px 50px 10px;
}

.card-swiper-4 {
  animation: bounce-4 3s infinite ease-in-out;
  background-color: white;
  height: 80%;
  width: 300px;
  align-items: center;
  /* justify-content: center; */
  display: flex;
  font-size: 35px;
  color: #532e8f;
  font-weight: bold;
  border-radius: 20px;
  margin: 20px;
  flex-direction: column;
  padding: 50px 10px 50px 10px;
}

.card-swiper-5 {
  animation: bounce-5 3s infinite ease-in-out;
  background-color: white;
  height: 80%;
  width: 300px;
  align-items: center;
  /* justify-content: center; */
  display: flex;
  font-size: 35px;
  color: #532e8f;
  font-weight: bold;
  border-radius: 20px;
  margin: 20px;
  flex-direction: column;
  padding: 50px 10px 50px 10px;
}

.card-swiper p,
.card-swiper-2 p,
.card-swiper-3 p,
.card-swiper-4 p,
.card-swiper-5 p {
  color: #333333;
  /* font-weight: 500; */
  font-size: 1.1rem;
  margin-top: 60px;
}

@keyframes bounce {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes bounce-2 {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(10px);
  }
}

@keyframes bounce-3 {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes bounce-4 {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(10px);
  }
}

@keyframes bounce-5 {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-5px);
  }
}

.container1 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card1 {
  transform-style: preserve-3d;
  min-height: 80vh;
  width: 35rem;
  border-radius: 30px;
  padding: 0rem 5rem;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0px 0px 50px rgba(0, 0, 0, 0.2);
}

.sneaker {
  min-height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sneaker img {
  width: 20rem;
  z-index: 2;
  transition: all 0.75s ease-out;
}
.circle {
  width: 15rem;
  height: 15rem;
  background: linear-gradient(
    to right,
    rgba(245, 70, 66, 0.75),
    rgba(8, 83, 156, 0.75)
  );
  position: absolute;
  border-radius: 50%;
  z-index: 1;
}

.info h1 {
  font-size: 3rem;
  transition: all 0.75s ease-out;
}
.info h3 {
  font-size: 1.3rem;
  padding: 2rem 0rem;
  color: #585858;
  font-weight: lighter;
  transition: all 0.75s ease-out;
}
.sizes {
  display: flex;
  justify-content: space-between;
  transition: all 0.75s ease-out;
}
.sizes button {
  padding: 0.5rem 2rem;
  background: none;
  border: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  cursor: pointer;
  font-weight: bold;
  color: #585858;
}
button.active {
  background: #585858;
  color: white;
}
.purchase {
  margin-top: 5rem;
  transition: all 0.75s ease-out;
}
.purchase button {
  width: 100%;
  padding: 1rem 0rem;
  background: #f54642;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 30px;
  font-weight: bolder;
}
